const FavsActionTypes = {
  UPDATE_FAVS_START: "UPDATE_FAVS_START",
  UPDATE_FAVS_SUCCESS: "UPDATE_FAVS_SUCCESS",
  UPDATE_FAVS_FAIL: "UPDATE_FAVS_FAIL",
  FETCH_FAVSITEMS_START: "FETCH_FAVSITEMS_START",
  FETCH_FAVSITEMS_SUCCESS: "FETCH_FAVSITEMS_SUCCESS",
  FETCH_FAVSITEMS_FAIL: "FETCH_FAVSITEMS_FAIL",
  SET_FAVS_IDS: "SET_FAVS_IDS",
  SET_FAVS_ITEMS: "SET_FAVS_ITEMS",
};

export default FavsActionTypes;
