const ReviewsActionTypes = {
  FETCH_REVIEWS_START: "FETCH_REVIEWS_START",
  FETCH_REVIEWS_SUCCESS: "FETCH_REVIEWS_SUCCESS",
  FETCH_REVIEWS_FAIL: "FETCH_REVIEWS_FAIL",
  SUBMIT_REVIEW_START: "SUBMIT_REVIEW_START",
  SUBMIT_REVIEW_SUCCESS: "SUBMIT_REVIEW_SUCCESS",
  SUBMIT_REVIEW_FAIL: "SUBMIT_REVIEW_FAIL",
  CLEAR_REVIEWS: "CLEAR_REVIEWS",
  DID_USER_REVIEW: "DID_USER_REVIEW",
  FETCH_MORE_REVIEWS_START: "FETCH_MORE_REVIEWS_START",
  SET_FETCH_PARAMS: "SET_FETCH_PARAMS",
};

export default ReviewsActionTypes;
