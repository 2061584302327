const ShopActionTypes = {
  FETCH_ITEMS_START: "FETCH_ITEMS_START",
  FETCH_ITEMS_SUCCESS: "FETCH_ITEMS_SUCCESS",
  FETCH_ITEMS_FAILED: "FETCH_ITEMS_FAILED",
  SET_ITEMS_CACHE: "SET_ITEM_CACHE",
  SET_SEARCH_PARAMS: "SET_SEARCH_PARAMS",
  SET_SEARCH_RESULTS: "SET_SEARCH_RESULTS",
  SET_INDEX_MAP: "SET_INDEX_MAP",
  CLEAR_ITEMS: "CLEAR_ITEMS",
};

export default ShopActionTypes;
